import { clsx } from 'clsx'
import { Link } from '@remix-run/react'
import { headingSizes } from '~/components'

interface Props {
  active: 'login' | 'register'
}

const LoginTabs = ({ active }: Props) => (
  <div className="mx-auto mb-8 grid grid-cols-2">
    <div>
      <h4
        className={clsx(
          `mb-0 block text-center font-semibold ` + headingSizes.h6,
          {
            'text-teal-green': active == 'login',
            'text-neutral-900': active !== 'login',
          }
        )}
      >
        <Link
          to="/login"
          className={clsx('block border-b-2 pb-5', {
            'border-teal-green': active == 'login',
            'border-neutral-200': active !== 'login',
          })}
        >
          Sign In
        </Link>
      </h4>
    </div>
    <div>
      <h4
        className={clsx(
          `mb-0 block text-center font-semibold ` + headingSizes.h6,
          {
            'text-teal-green': active == 'register',
            'text-neutral-900': active !== 'register',
          }
        )}
      >
        <Link
          to="/register"
          className={clsx('block border-b-2 pb-5', {
            'border-teal-green': active == 'register',
            'border-neutral-200': active !== 'register',
          })}
        >
          Create Account
        </Link>
      </h4>
    </div>
  </div>
)

export default LoginTabs
